<script setup>
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {register} from 'swiper/element/bundle'
register()

const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
})

onMounted(() => {
  setTimeout(() => {
    // pin image section when main component
    // reaches the top of the viewport
    ScrollTrigger.create({
      group: 'tlPanels',
      trigger: '.full-text-component',
      start: 'top 0%',
      end: 'bottom 100%',
      pin: '.images-wrapper',
      pinSpacing: false,
      markers: false,
    })

    const panels = gsap.utils.toArray('.panel')

    panels.forEach((panel) => {
      ScrollTrigger.create({
        group: 'tlPanels',
        trigger: panel,
        start: 'top center',
        end: 'bottom center',
        markers: false,
        toggleClass: 'active',
        onEnter: () => activePanelImage(panel),
        onEnterBack: () => activePanelImage(panel),
        onLeave: () => deactivePanelImage(panel),
        onLeaveBack: () => deactivePanelImage(panel),
      })
    })
  }, 1500)
})

const activePanelImage = (panel) => {
  panel.classList.add('active')
  document.querySelector('.' + panel.dataset.target).classList.add('active')
}

const deactivePanelImage = (panel) => {
  panel.classList.remove('active')
  document.querySelector('.' + panel.dataset.target).classList.remove('active')
}

onBeforeUnmount(() => {
  let getScrollTriggerGroup = (group) =>
    ScrollTrigger.getAll().filter((t) => t.vars.group === group)
  getScrollTriggerGroup('tlPanels').forEach((t) => t.kill())
})
</script>

<template>
  <transition>
    <div class="full-text-component">
      <div class="internal-wrapper">
        <div class="images-wrapper">
          <div
            class="image-panel"
            :class="['image-panel--' + index]"
            v-for="(slide, index) in props.slides"
            :key="index">
            <picture class="image-panel-bg" v-if="slide.assets">
              <source
                v-for="asset in filterPictureSourceTags(slide.assets)"
                :srcset="asset.src"
                :type="asset.format"
                :media="asset.media" />
              <img
                v-for="asset in filterPictureImageTag(slide.assets)"
                :srcset="asset.src"
                :alt="slide.title + ' - Pininfarina'" />
            </picture>
          </div>
        </div>
        <div class="panels-wrapper">
          <div
            class="panel"
            :class="['panel--' + index]"
            v-for="(slide, index) in props.slides"
            :key="index"
            :data-target="'image-panel--' + index">
            <div class="panel-content">
              <h2 class="panel-title">{{ slide.title }}</h2>
              <div class="panel-description" v-html="slide.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.full-text-component {
  position: relative;
}

.internal-wrapper {
  display: flex;
  position: relative;
}

.panels-wrapper {
  position: relative;
  width: 100%;
}

.panel {
  align-items: center;
  display: flex;

  justify-content: center;
  height: calc(100vh);

  user-select: none;

  &::before {
    background-color: $color-blue;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    opacity: 0.35;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &.active {
    .panel-content {
      filter: blur(0);
    }
  }

  .panel-content {
    filter: blur(30px);
    max-width: 80%;
    position: relative;
    transition: filter 750ms cubic-bezier(0.19, 0.62, 0.49, 0.99);
    z-index: 2;
  }

  .panel-title {
    color: $color-white;
    font-size: $font-xlarge;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;

    @include mq(md) {
      font-size: $font-xxlarge;
    }

    @include mq(lg) {
      font-size: $font-xxxlarge;
    }

    @include mq(xl) {
      font-size: $font-xhuge;
    }

    @include mq(xxl) {
      font-size: $font-xxhuge;
      font-size: 12.5vw;
    }
  }

  .panel-description {
    @include display-2($color-white);
    margin: pxrem(20) auto;

    text-align: center;

    @include mq(md) {
      margin: pxrem(40) auto;
      max-width: 60%;
    }
  }
}

.images-wrapper {
  position: relative;
  width: 0;
}

.image-panel {
  opacity: 0;
  transition: opacity $transition-default-extraslow;

  &.active {
    opacity: 1;
  }
}
* .image-panel-bg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  img {
    height: calc(100vh);
    max-width: 100vw;
    object-fit: cover;
    width: 100vw;
  }
}
</style>
