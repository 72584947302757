<script setup>
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
  },
})

// const nuxtApp = useNuxtApp()

const extractVideoCode = (input) => {
  const pattern =
    /(?:\/|v=|be\/|embed\/|youtu\.be\/|watch\?v=|watch\?.+&v=|^)([a-zA-Z0-9_-]{11})/
  const match = input.match(pattern)

  if (match && match[1]) {
    return match[1]
  }

  return null
}

const videoId = ref(extractVideoCode(props.content.url))
const paragraphPreview = ref()
const playerContainer = ref()
// let player = null

// nuxtApp.hook('page:transition:finish', () => {
//   // Load the YouTube Player API
//   const tag = document.createElement('script')
//   tag.src = 'https://www.youtube.com/iframe_api'
//   const firstScriptTag = document.getElementsByTagName('script')[0]
//   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

//   // Initialize the YouTube player
//   if (window && videoId.value) {
//     console.log('videoId.value', videoId.value)
//     window.onYouTubeIframeAPIReady = () => {
//       player = new window.YT.Player(playerContainer.value, {
//         height: '360',
//         width: '640',
//         videoId: videoId.value,
//       })
//     }
//   }
// })

// const onPlayerReady = (event) => {
//   paragraphPreview.value.classList.add('paragraph-preview--hidden')
//   playerContainer.value?.playVideo()
// }

const onPlayClick = (event) => {
  paragraphPreview.value.classList.add('paragraph-preview--hidden')
  console.log('PlayerContainer:', playerContainer.value)
  if(!playerContainer.value?.getPlayerInstance()) playerContainer.value?.addIframe()
  // Waiting for iframe to be generated
  setTimeout(() => {
    playerContainer.value?.playVideo()
  }, 100)
}
</script>

<template>
  <section class="paragraph paragraph--embedded-video">
    <div class="paragraph-wrapper">
      <div class="paragraph-video-wrapper animate-in-bottom animate-out-right">
        <div
          class="paragraph-preview sensible"
          ref="paragraphPreview"
          @click="onPlayClick">
          <img class="preview-image" :src="content.placeholder" />
          <div class="preview-cta">
            <div class="btn btn--round btn--round-light">
              <svg class="icon icon--btn-round">
                <use xlink:href="#icon-play"></use>
              </svg>
            </div>
            <span>Play video</span>
          </div>
        </div>
        <LiteYouTubeEmbed :id="videoId" ref="playerContainer" :announce="'Video '+videoId" :title="'Video '+videoId" />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.paragraph--embedded-video {
  .paragraph-wrapper {
    margin-bottom: pxrem(50);
    user-select: none;

    @include mq(md) {
      margin-bottom: pxrem(85);
    }
  }

  .paragraph-video-wrapper {
    aspect-ratio: 16 / 9;
    height: auto;
    overflow: hidden;
    position: relative;
    width: 100%;

    .paragraph-preview {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity $transition-default 300ms;
      z-index: 1;

      &.paragraph-preview--hidden {
        opacity: 0;
        pointer-events: none;
      }

      &::before {
        background: linear-gradient(
          180deg,
          rgba(8, 20, 39, 0.04) 0%,
          rgba(8, 20, 39, 1) 100%
        );
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .preview-image {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      width: 100%;
    }

    .preview-cta {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      .btn {
        margin-bottom: pxrem(20);
      }

      span {
        @include label($color-white);
      }
    }

    iframe {
      aspect-ratio: 16 / 9;
      height: auto;
      width: 100%;
    }
  }
}
</style>
