<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  pattern: {
    type: Boolean,
    default: false,
  },
})

const accordionParagraph = resolveComponent('SharedParagraphsAccordion')
const areasParagraph = resolveComponent('SharedParagraphsAreas')
const awardsParagraph = resolveComponent('SharedParagraphsAwards')
const bannerParagraph = resolveComponent('SharedParagraphsBanner')
const careersParagraph = resolveComponent('SharedParagraphsCareers')
const certificationsParagraph = resolveComponent(
  'SharedParagraphsCertifications'
)
const contactsMoreParagraph = resolveComponent('SharedParagraphsContactsMore')
const ctasBigParagraph = resolveComponent('SharedParagraphsCtasBig')
const downloadsParagraph = resolveComponent('SharedParagraphsDownloads')
const embeddedVideoParagraph = resolveComponent('SharedParagraphsEmbeddedVideo')
const galleryParagraph = resolveComponent('SharedParagraphsSlider')
const galleryDescriptionMultipleParagraph = resolveComponent(
  'SharedParagraphsSliderDescriptionMultiple'
)
const galleryDescriptionSingleParagraph = resolveComponent(
  'SharedParagraphsSliderDescriptionSingle'
)
const galleryTechnicalParagraph = resolveComponent(
  'SharedParagraphsSliderTechnical'
)
const imageTitleTextCtaParagraph = resolveComponent(
  'SharedParagraphsImageTitleTextCta'
)
const introductionParagraph = resolveComponent('SharedParagraphsIntroduction')
const investorRelationsParagraph = resolveComponent(
  'SharedParagraphsInvestorRelations'
)
const listBoxParagraph = resolveComponent('SharedParagraphsListBox')
const mapParagraph = resolveComponent('SharedParagraphsMap')
const mediahubListingPublicParagraph = resolveComponent(
  'SharedParagraphsMediaHubListingPublic'
)
const news3Paragraph = resolveComponent('SharedParagraphsNews3')
const news9Paragraph = resolveComponent('SharedParagraphsNews9')
const newsletterArchive = resolveComponent('SharedParagraphsNewsletterArchive')
const newsListParagraph = resolveComponent('SharedParagraphsNewsList')
const officesHomeParagraph = resolveComponent('SharedParagraphsOfficesHome')
const officesListParagraph = resolveComponent('SharedParagraphsOfficesList')
const partnersParagraph = resolveComponent('SharedParagraphsPartners')
const pretitleTitleTextParagraph = resolveComponent(
  'SharedParagraphsPretitleTitleText'
)
const pretitleTitleTextAndgalleryParagraph = resolveComponent(
  'SharedParagraphsPretitleTitleTextAndSlider'
)

const projects3Paragraph = resolveComponent('SharedParagraphsProjects3')
const projects6Paragraph = resolveComponent('SharedParagraphsProjects6')
const screamerTextParagraph = resolveComponent(
  'SharedParagraphsScreamerTextFull'
)
const sectorsAboutParagraph = resolveComponent('SharedParagraphsSectorsAbout')
const sectorsHomeParagraph = resolveComponent('SharedParagraphsSectorsHome')
const sliderFullTextParagraph = resolveComponent(
  'SharedParagraphsSliderFullText'
)
const sliderProcessParagraph = resolveComponent('SharedParagraphsSliderProcess')
const sliderTeamParagraph = resolveComponent('SharedParagraphsSliderTeam')
const teamParagraph = resolveComponent('SharedParagraphsTeam')
const titleTextCtaParagraph = resolveComponent('SharedParagraphsTitleTextCta')
const textParagraph = resolveComponent('SharedParagraphsText')
const timeMuseumParagraph = resolveComponent('SharedParagraphsTimeMuseum')
const titleTextCtaNumberParagraph = resolveComponent(
  'SharedParagraphsTitleTextCtaNumber'
)
const verticalTabsParagraph = resolveComponent('SharedParagraphsVerticalTabs')

const getParagraphComponent = (type, layout) => {
  switch (type) {
    case 'accordion':
      return accordionParagraph
    case 'areas':
      return areasParagraph
    case 'awards':
      return awardsParagraph
    case 'banner':
      return bannerParagraph
    case 'careers':
      return careersParagraph
    case 'certifications':
      return certificationsParagraph
    case 'contacts_more':
      return contactsMoreParagraph
    case 'ctas_big':
      return ctasBigParagraph
    case 'downloads':
      return downloadsParagraph
    case 'embedded_video':
      return embeddedVideoParagraph
    case 'gallery':
      return galleryParagraph
    case 'gallery_with_description_multiple':
      return galleryDescriptionMultipleParagraph
    case 'gallery_with_description_single':
      return galleryDescriptionSingleParagraph
    case 'gallery_full_text':
      return sliderFullTextParagraph
    case 'gallery_technical':
      return galleryTechnicalParagraph
    case 'image_title_text_cta':
      return imageTitleTextCtaParagraph
    case 'investor_relations':
      return investorRelationsParagraph
    case 'info_museum':
      return timeMuseumParagraph
    case 'intro':
      return introductionParagraph
    case 'list_box':
      return listBoxParagraph
    case 'map':
      return mapParagraph
    case 'mediahub':
      return mediahubListingPublicParagraph
    case 'newsletter_archive':
      return newsletterArchive
    case 'news_3':
      return news3Paragraph
    case 'news_6':
      return news9Paragraph
    case 'news_9':
      return news9Paragraph
    case 'news_list':
      return newsListParagraph
    case 'offices_home':
      return officesHomeParagraph
    case 'offices_list':
      return officesListParagraph
    case 'partners':
      return partnersParagraph
    case 'pretitle_title_text':
      return pretitleTitleTextParagraph
    case 'pretitle_title_text_and_gallery':
      return pretitleTitleTextAndgalleryParagraph
    case 'projects':
      if (layout == 6) {
        return projects6Paragraph
      } else if (layout == 3) {
        return projects3Paragraph
      }
    case 'screamer_text':
      return screamerTextParagraph
    case 'sectors_about':
      return sectorsAboutParagraph
    case 'sectors_home':
      return sectorsHomeParagraph
    case 'tabs':
      return verticalTabsParagraph
    case 'team_1':
      return teamParagraph
    case 'team_2':
      return sliderTeamParagraph
    case 'text':
      return textParagraph
    case 'title_text':
      return pretitleTitleTextParagraph
    case 'title_text_cta':
      return titleTextCtaParagraph
    case 'title_text_and_gallery_with_description':
      return sliderProcessParagraph
    case 'title_text_cta_number':
      return titleTextCtaNumberParagraph
    default:
      return ''
  }
}

// Managing components errors
const errorMessages = ref({})

onErrorCaptured((err, instance) => {
  console.error('Error captured in component:', err)
  
  const index = instance?.$attrs?.['data-index']
  
  if( index !== undefined ){
    errorMessages.value[index] = err.message
  }

  return false
})
</script>

<template>
  <div v-for="(paragraph, index) in content" :key="index">
    <template v-if="errorMessages[index]">
      <section class="paragraph paragraph--component-error h-hide">
        <h4 class="error-message">Error on paragraph "{{ paragraph.type }}": {{ errorMessages[index] }}</h4>
      </section>
    </template>
    <template v-else>
      <component
        :is="getParagraphComponent(paragraph.type, paragraph.layout)"
        :content="paragraph"
        :pattern="pattern"
        :data-index="index"
      />
    </template>
  </div>
</template>

<style lang="scss">
.paragraph {
  @include make-col-ready();

  @include mq(md) {
    @include make-col(22);
    @include make-col-offset(1);
  }

  &.paragraph--list-box,
  &.paragraph--pretitle-title-text,
  &.paragraph--pretitle-title-text-and-slider {
    @include make-col-ready();

    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(2);
    }
  }

  &.paragraph--areas,
  &.paragraph--ctas-big,
  &.paragraph--slider-full-text,
  &.paragraph--slider-technical {
    padding-left: 0;
    padding-right: 0;

    @include mq(md) {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  &.paragraph--slider-process,
  &.paragraph--slider-technical,
  &.paragraph--slider-description-multiple,
  &.paragraph--slider-description-single {
    background-color: $color-gray-light;
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  &.paragraph--component-error{
    .error-message{
      color: $color-red;
    }
  }
}
</style>
