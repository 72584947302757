<script setup>
import {useLocalePath} from 'vue-i18n-routing'
const localePath = useLocalePath()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const swiperContainer = ref()
const showNavigation = ref(false)

const swiperParams = {
  rewind: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 'auto',
  spaceBetween: 40,
  breakpoints: {
    0: {
      centeredSlides: true,
      // loop: true,
      spaceBetween: 20,
      simulateTouch: true,
    },
    576: {
      centeredSlides: false,
      // loop: false,
      spaceBetween: 40,
      simulateTouch: false,
    }
  }
}

function updateNavigationVisibility() {
  nextTick(() => {
    if (swiperContainer.value?.swiper) {
      const swiper = swiperContainer.value.swiper
      const totalSlides = swiper.slides.length || 0
      const visibleSlides = Math.floor(swiper.el.clientWidth / swiper.slides[0].clientWidth) || 1

      // Checking if last slide is completely visible
      const lastSlide = swiper.slides[totalSlides - 1]
      const lastSlideRightEdge = lastSlide.offsetLeft + lastSlide.clientWidth
      const swiperRightEdge = swiper.el.clientWidth + swiper.el.scrollLeft

      showNavigation.value = totalSlides > visibleSlides || lastSlideRightEdge > swiperRightEdge
    }
  })
}

function initializeSlider() {
  if (swiperContainer.value) {
    Object.assign(swiperContainer.value, swiperParams)
    swiperContainer.value.initialize()

    setTimeout(updateNavigationVisibility, 500)
  }
}

let initialized = false

onMounted(() => {
  if (!initialized) {
    setTimeout(() => {
      initializeSlider()
      initialized = true
    }, 1000)
  }

  window.addEventListener('resize', updateNavigationVisibility)
})

onBeforeUnmount(() => {
  if (swiperContainer.value?.swiper) {
    swiperContainer.value.swiper.destroy()
  }
  window.removeEventListener('resize', updateNavigationVisibility)
})
</script>

<template>
  <section class="section section--selected-partners">
    <div class="section-wrapper">
      <div class="section-header">
        <h2 class="section-title animate-in-bottom animate-out-left">
          {{ (content.title)? content.title : $t('selected_partners') }}
        </h2>
      </div>
      <div class="section-body">
        <div
          class="swiper swiper--partners animate-in-bottom animate-out-right">
          <swiper-container
            init="false"
            ref="swiperContainer"
            events-prefix="swiper">
            <div slot="container-end">
              <div v-show="showNavigation" class="swiper-navigation">
                <div class="swiper-button swiper-button-prev sensible">
                  <div class="btn btn--round btn--round-dark">
                    <svg class="icon icon--btn-round icon--rotate-180">
                      <use xlink:href="#icon-arrow"></use>
                    </svg>
                  </div>
                </div>
                <div class="swiper-button swiper-button-next sensible">
                  <div class="btn btn--round btn--round-dark">
                    <svg class="icon icon--btn-round">
                      <use xlink:href="#icon-arrow"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <swiper-slide class="swiper-slide" v-for="(partner, index) in content.list" :key="index">
              <img v-if="partner.image" :src="partner.image" :alt="partner.name" />
              <div v-else>{{ partner.name }}</div>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section--selected-partners {
  margin-bottom: pxrem(60);
  margin-top: pxrem(60);

  @include mq(md) {
    margin-bottom: pxrem(100);
    margin-top: pxrem(100);
  }
  .section-wrapper {
    @include make-col-ready;

    @include mq(md) {
      @include make-col(20);
      @include make-col-offset(2);
    }
  }

  .section-header {
    margin-bottom: pxrem(40);

    @include mq(md) {
      margin-bottom: pxrem(80);
    }
  }

  .section-title {
    @include display-2;
    font-weight: 400;
  }
}

.swiper--partners {
  .swiper-slide {
    align-items: center;
    display: flex;
    height: auto;
    width: auto;

    &:hover {
      img {
        filter: grayscale(0);
      }
    }

    img {
      display: block;
      max-height: pxrem(100);
      max-width: pxrem(200);
      transition: filter $transition-default;

      @include mq(md) {
        filter: grayscale(100%);
      }
    }
  }

  .swiper-navigation {
    margin-top: pxrem(50);
    text-align: center;

    .swiper-button-prev {
      margin-right: pxrem(8);
    }
  }
}
</style>
